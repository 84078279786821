
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





















































































































































































































































































.app-file-uploader {
  &__main {
    background: white;
    border-radius: $--clb-border-radius;
    border: 2px dashed $--clb-border-color-lighter;

    &__label {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: $--clb-layout-4 $--clb-space-6;
      border-radius: $--clb-border-radius;

      &-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        &__icon {
          font-size: $--clb-layout-5;
          opacity: 0.15;
        }

        &__message {
          max-width: 200px;
          margin-top: $--clb-space-2;
          text-align: center;

          &.error {
            color: $--clb-color-error-text;
          }

          &.dragover {
            color: $--clb-color-primary;
          }
        }
      }

      &.dragover {
        border: 2px dashed $--clb-color-primary;
        background-color: $--clb-color-grey__white-ter;

        .app-file-uploader__label-inner__icon {
          opacity: 1;
          font-size: $--clb-layout-3;
          color: $--clb-color-primary;
        }
      }

      &.loading {
        cursor: not-allowed;
      }

      &.error {
        border: 2px dashed $--clb-color-error-text;
        background-color: $--clb-color-error-background;

        .app-file-uploader__label-inner__icon {
          font-size: 30px;
          opacity: 1;
          color: $--clb-color-error-text;
        }
      }

      .app-file-input {
        display: none;
      }
    }
  }

  &__error-section {
    margin: $--clb-space-4 0;
    padding: $--clb-space-4;
    background-color: $--clb-color-error-background;

    .el-icon-error {
      color: $--clb-color-error-text;
    }
  }
}
