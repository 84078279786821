
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































































































































































































































































































































































































.ad-approval-row {
  border: 1px solid $--clb-border-color-base;
  border-radius: $--clb-border-radius;
  width: 100%;
  font-weight: $--clb-font-weight__semi-bold;
  margin-bottom: $--clb-space-2;
  overflow-x: auto;

  &__ad-approval-history {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;

    &.active {
      transform: scaleY(1);
    }
  }

  &__affiliate-actions-review-btn {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100% !important;
  }

  &.selected {
    background: $--clb-color-primary__lighter;
  }

  &__inner {
    display: flex;

    &-tag {
      min-width: 2px;
      margin: $--clb-space-2  0 $--clb-space-2 #{$--clb-space-2 - 2px};
      border-radius: $--clb-border-radius;

      &-color {
        &-red {
          background-color: $--clb-color-red;
        }

        &-orange {
          background-color: $--clb-color-warning;
        }

        &-green {
          background-color: $--clb-color-green;
        }
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    width: 100%;
    padding: $--clb-space-4;
    line-height: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include xs-down {
      flex-wrap: wrap;
      flex-direction: row-reverse;
    }

    .ad-approval-row-content__info {
      @include xs-down {
        padding-bottom: $--clb-space-3;
        margin-bottom: $--clb-space-3;
        border-bottom: $--clb-border-complete-light;
      }
    }

    .vue-portal-target {
      display: flex;
    }

    &__review,
    &__revoke {
      display: flex;
      align-items: center;

      &-btn {
        padding: 0 $--clb-space-2;
      }
    }

    &__history-toggle {
      display: flex;
      align-items: center;
      margin-left: $--clb-space-4;

      .toggle {
        margin-left: $--clb-space-4;

        &-btn {
          padding: 0;

          i {
            transition: all 0.4s ease;

            &.active {
              transform: rotateZ(-180deg);
              transform-origin: center;
            }
          }
        }

        &-seperator {
          background-color: $--clb-color-grey__grey-lighter;
          width: 1px;
          height: 100%;
        }
      }
    }

    .add-note {
      &-section {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding-top: $--clb-space-1;
        margin-bottom: $--clb-space-4;

        &__note-icon {
          padding: 0 $--clb-space-2;
        }

        &__edit {
          display: flex;
          align-items: center;
          width: 100%;

          &__input {
            flex-grow: 1;
          }
        }

        .delete-note-btn,
        .delete-btn {
          padding: 0;
          margin-left: $--clb-space-4;
        }
      }

      &-btn {
        padding: 0;
      }
    }
  }

  &__message-box {
    .el-input__inner {
      font-size: $--clb-font-size-base;
      height: 35px; // mini
      min-height: 35px !important;
    }
  }
}
