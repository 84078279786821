
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
















































































































































































.ad-approval-visual-content {
  &__uploader {
    border-radius: $--clb-border-radius;

    @include xs-up {
      margin-right: $--clb-layout-3;
    }
  }

  &__main {
    margin-top: $--clb-space-4;
    display: flex;
    justify-content: space-between;

    > :not(:last-child) {
      border-bottom: $--clb-border-complete-light;
    }

    &__error {
      width: 100%;
      padding: $--clb-space-4;
    }

    &__images {
      display: flex;
      width: 100%;
      padding: $--clb-space-4;
      background: white;

      &__delete-btn {
        padding: 0;
        margin: 0 0 0 $--clb-space-4;
      }

      &-image {
        padding-right: $--clb-space-4;
      }

      &-input {
        flex-grow: 1;
      }
    }
  }

  .add-note {
    &-section {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-top: $--clb-space-1;

      &__note-icon {
        padding: 0 $--clb-space-4;
      }

      &__edit {
        display: flex;
        align-items: center;
        width: 100%;

        &__input {
          flex-grow: 1;
        }
      }

      .delete-note-btn,
      .delete-btn {
        padding: 0;
        margin-left: $--clb-space-4;
      }
    }

    &-btn {
      padding: 0;
    }
  }
}

.ad-approval-visual-content__icon {
  @apply sm:tw-hidden tw-block tw-text-right;
}

