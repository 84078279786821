
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































.ad-approval-settings__container {
  margin-top: $--clb-space-1;
  display: flex;
  flex-direction: column;

  &--top-row {
    display: flex;
    flex-direction: column;

    @include sm-up {
      flex-direction: row;
    }
  }
}
