
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






















































































































.ad-approval-row {
  &__affiliate-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-copy-btn,
    &-edit-btn {
      padding: 0 $--clb-space-2;
    }

    &-submit {
      display: flex;

      &-cancel-btn {
        margin-right: $--clb-space-3;
      }

      @include xs-down {
        align-self: center;
      }

      align-self: flex-end;
    }
  }
}
