
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































































































.ad-approval-table {
  margin-top: $--clb-space-5;

  &-header {
    display: flex;

    > span {
      font-size: $--clb-font-size-sm;
      padding-right: $--clb-layout-1;
      cursor: pointer;
    }

    @include xs-down {
      :last-child {
        display: none;
      }
    }
  }

  &-content {
    margin-top: $--clb-space-2;
  }

  svg.skeleton-box {
    margin: 0;
  }

  &__pagination {
    margin-top: $--clb-space-2;
    padding: 0;

    .el-pager {
      .number {
        color: $--clb-color-grey__grey;
        box-shadow: 0 2px 0 rgba(white, 0);
        transition: $--clb-hover-transition;

        &:hover {
          color: $--clb-color-grey__grey-dark;
        }

        &.active {
          color: $--clb-color-primary;
        }
      }
    }
  }
}
