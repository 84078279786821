
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



























































































































































































































































































































.ad-approval-layout {
  @include stage-layout;

  @apply tw-mt-0;

  &__heading {
    &-btns {
      display: flex;

      .ad-approval-request {
        &__text {
          margin-left: $--clb-space-4;
        }

        @include xs-down {
          &__btn {
            font-size: $--clb-layout-2;
            color: $--clb-color-primary;
            padding: $--clb-space-1;
            padding-top: 0;
          }
        }
      }

      .ad-approval-settings-btn {
        font-size: $--clb-layout-2;

        @include xs-down {
          padding: $--clb-space-1;
          padding-top: 0;
          padding-left: $--clb-space-4;
        }
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap-reverse;
  }

  &__tabs {
    width: 100%;
    z-index: 0;

    .el-tabs__header {
      margin-bottom: $--clb-space-2;
    }

    .el-tabs__active-bar {
      @apply tw-bg-jb-indigo;
    }

    .el-tabs__item {
      height: $--clb-layout-4;
      line-height: $--clb-layout-4;
      font-size: $--clb-font-size-base;
      color: $--clb-color-grey__grey-darker;

      &.is-active {
        color: $--clb-color__headings;
      }
    }

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: $--clb-layout-4;
    }

    .el-tabs__nav {
      .el-tabs__active-bar {
        height: 3px;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s ease-in-out;
      }
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    &.freeze {
      .el-tabs__active-bar {
        transition: none;
      }
    }
  }

  .ad-approval-request {
    &__text {
      margin-left: $--clb-space-4;
    }

    @include xs-down {
      &__btn {
        font-size: $--clb-layout-2;
        color: $--clb-color-primary;
        padding: $--clb-space-1;
        padding-top: 0;
      }
    }
  }

  .ad-approval-settings-btn {
    font-size: $--clb-layout-2;

    @include xs-down {
      padding: $--clb-space-1;
      padding-top: 0;
      padding-left: $--clb-space-4;
    }
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 600px;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }
}

.app-header__portal .ad-approval-layout {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;

  &__tabs {
    transition: $--all-transition;

    .el-tabs__header {
      margin-bottom: 0;
    }

    @apply xs:tw-px-layout-1 sm:tw-px-layout-4 md:tw-px-layout-3 lg:tw-px-layout-5;
  }
}
