
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























































































































































.ad-approval-response-time {
  margin: 0 0 $--clb-layout-1 0;
  padding: $--clb-space-4;
  border-radius: $--clb-border-radius;
  border: $--clb-border-complete-light;
  min-width: 18rem;

  @include xs-up {
    min-width: 19rem;
  }

  @include sm-up {
    margin: 0 $--clb-layout-1 $--clb-layout-1 0;
  }

  &__content {
    .el-icon-edit-outline:hover {
      cursor: pointer;
    }

    &-header__instructions {
      margin-bottom: $--clb-space-4 !important;
      max-width: 336px;
    }

    &__input {
      display: flex;
      align-items: center;
      margin-left: $--clb-space-4;

      .el-input {
        width: 57px;
        min-width: 57px;
        border-color: grey;
        border-radius: $--clb-space-1;
        margin-right: $--clb-space-4;
        padding: 0;

        input {
          font-size: $--clb-h2__font-size;
          text-align: center;
          font-weight: $--clb-font-weight__medium;
          padding: 0;
          transition: border 0.2s;
        }

        &.border-error .el-input__inner {
          border-color: $--clb-color-danger;
          color: $--clb-color-danger;
        }

        &.border-success .el-input__inner {
          border-color: $--clb-color-success;
          color: $--clb-color-success;
        }

        &.hide-border .el-input__inner {
          &:hover {
            border: 1px solid  $--clb-color-primary !important;
          }

          border: transparent;
          transition: border-color 0.2s;
        }
      }
    }

    &-hour {
      margin-right: $--clb-space-6;
    }

    &-time {
      width: 50px;
      font-size: $--clb-h2__font-size !important;
      font-weight: $--clb-font-weight__medium !important;
      border-color: $--clb-color-grey__white-ter;
      border-radius: $--clb-space-1;
      margin: 0 !important;
    }
  }

  &__feedback {
    width: 10rem;

    &.error {
      color: $--clb-color-danger;
    }

    &.saved {
      color: $--clb-color-success;
    }
  }
}
