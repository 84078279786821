
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
































































































@import '~quill/dist/quill.snow.css';

.ad-approval-dos-and-donts {
  padding: $--clb-space-4;
  border-radius: $--clb-border-radius;
  border: $--clb-border-complete-light;

  &__heading {
    margin-bottom: $--clb-space-2 !important;
  }

  &__sub-heading {
    margin-bottom: $--clb-space-1;
  }

  &__edit {
    &-btn {
      display: inline;
      padding: $--clb-space-1;
    }

    &-icon {
      color: $--clb-body-font;
      transition: all 0.3s;

      &.active {
        color: $--clb-color-grey__grey-light;
      }
    }

    .quillWrapper {
      margin-bottom: $--clb-space-3;
    }
  }

  &__view {
    .ql-editor {
      padding: $--clb-space-4;
      background-color: $--clb-color-grey__white-est;
      border-radius: $--clb-border-radius;
    }
  }

  &__save {
    margin-top: $--clb-space-3;
  }
}
