
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
























































































































.ad-approval-row-content {
  video {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: $--clb-color-grey__grey-light;
    border-radius: $--clb-border-radius;
    overflow: hidden;
    transition: transform $--clb-hover-transition, box-shadow $--clb-hover-transition, border $--clb-hover-transition;
    border: 1px solid $--clb-border-color-base;
    transform: scale(1.01);
    object-fit: cover;

    &:hover {
      border: 1px solid $--clb-color-primary;
      box-shadow: 0 2px 8px 0 $--clb-shadow-color;
      cursor: pointer;
      transform: scale(1.08);
      transform: translateY(-4px) !important;
    }
  }
}
