
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

































































































































.ad-approval-row {
  &__advertiser-actions {
    display: flex;
    flex-direction: row;
    font-size: $--clb-font-size-xl;
    align-items: center;
    justify-content: flex-end;

    @include xs-down {
      padding-top: $--clb-space-2;
    }

    &__btns {
      margin-left: $--clb-space-2;

      &:first-child {
        margin-left: 0;
      }

      &.review-btn {
        padding: 0 $--clb-space-1;
      }

      &.approve-btn,
      &.reject-btn {
        width: 80px;
      }
    }
  }

  &-switch {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: $--clb-layout-6;
    justify-content: space-between;

    .submit-btn {
      display: flex;
      align-self: flex-end;
      margin-top: auto;
    }

    &-toggle {
      text-align: right;
      position: relative;
      min-width: 200px;
      padding-top: $--clb-space-3;

      &__btn {
        color: $--clb-color-grey__grey;
        font-size: $--clb-font-size-sm;
        transition: all 0.3s ease-in;
        font-weight: $--clb-font-weight__normal;
        position: absolute;

        &.approve-with-edits {
          right: 40px;
          top: 2px;

          &.active {
            color: $--clb-color-green-darker;
          }
        }

        &.reject-with-notes {
          position: absolute;
          right: 40px;
          top: 25px;

          &.active {
            color: $--clb-color-red-darker;
          }
        }

        &.active {
          color: $--clb-color-primary;
          font-weight: $--clb-font-weight__bold;
        }
      }

      .el-switch__core {
        transform: rotate(270deg);
      }
    }
  }
}
