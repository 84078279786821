
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
















































































































































































.ad-approval-banned-words {
  margin: 0 0 $--clb-space-4 0;
  background: $--clb-color-primary__white;
  padding: $--clb-space-4;
  border-radius: $--clb-border-radius;
  border: $--clb-border-complete-light;
  min-width: 18rem;
  flex-grow: 1;

  &__header {
    display: flex;

    .app-button {
      padding: 0 0 $--clb-space-3 $--clb-space-2;

      .el-icon-edit {
        transition: color 0.3s;
        margin: $--clb-space-1 0 0 $--clb-space-1;
        color: $--clb-body-font;

        &.active {
          color: $--clb-color-grey__grey-light;
        }
      }
    }
  }

  &__input {
    max-width: 432px;
    margin-top: $--clb-space-3;
    display: flex;

    .el-input__inner {
      margin-bottom: $--clb-space-4 !important;
      max-width: 338px;
      min-height: 40px !important;
      max-height: 40px !important;
      font-size: $--clb-font-size-base;
    }
  }

  &__pills {
    display: flex;
    flex-wrap: wrap;

    @include md-up {
      margin: 0 0 $--clb-space-4 0;
    }

    &-word {
      display: flex;
      align-items: center;
      color: $--clb-color-accent__light;
      text-transform: lowercase;
      background: $--clb-color-grey__grey-lighter;
      padding: $--clb-space-2;
      margin: $--clb-space-1 $--clb-space-1 $--clb-space-1 0;
      border-radius: $--clb-space-1;
      font-size: $--clb-font-size-base;
      font-weight: $--clb-font-weight__bold;
      min-height: $--clb-layout-3;
      word-break: break-word;

      .el-icon-close {
        padding-right: $--clb-space-2;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__save-btn {
    margin-bottom: $--clb-space-3;
  }
}
