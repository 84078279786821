
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        











































































































































































































































































































.ad-approval-new-request {
  textarea {
    vertical-align: top;
  }

  background: $--clb-color-grey__white-ter;
  display: flex;
  border: $--clb-border-complete-light;
  border-radius: $--clb-border-radius;

  &__inner {
    width: 100%;
    padding: $--clb-layout-1;

    &-main {
      margin-top: $--clb-space-3;

      &-input {
        margin-bottom: $--clb-space-4;

        .delete-note-btn,
        .delete-btn {
          padding: 0;
          min-width: 40px;
          height: 33px;
          cursor: pointer;
        }
      }

      &-content {
        &__heading {
          display: flex;

          .add-btn {
            padding: 0 $--clb-space-2;
            height: $--clb-space-6;
          }

          &-btn {
            padding: 0;
          }
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-textarea {
          max-width: calc(100% - 40px);
        }
      }

      &-body,
      &-visual,
      &-headline {
        margin-bottom: $--clb-space-4;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;

      .cancel-ad-approval-new-request-view {
        margin-right: $--clb-space-2;
      }
    }
  }

  &__error {
    background: #f8e6e6;
    margin-top: $--clb-space-3;
    padding: $--clb-space-2;
    color: $--clb-body-font;
    border-radius: $--clb-border-radius;

    .el-icon-remove-outline {
      color: $--clb-color-red;
      font-weight: $--clb-font-weight__semi-bold;
    }
  }

  .add-note {
    &-section {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-top: $--clb-space-1;

      &__note-icon {
        padding: 0 $--clb-space-2;
      }

      &__edit {
        display: flex;
        align-items: center;
        width: 100%;

        &__input {
          flex-grow: 1;
        }
      }
    }

    &-btn {
      padding: 0;
      height: $--clb-space-6;
    }
  }
}
