
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




































































































































































































.ad-approval-history {
  padding: 0 $--clb-layout-1;
  background-color: $--clb-color-grey__white-ter;

  &--loading,
  &--empty {
    padding: $--clb-layout-1;
  }

  &:not(&--affiliate) {
    padding-left: $--clb-layout-2;

    .ad-approval-history__title {
      margin-top: $--clb-layout-2 !important;
    }
  }

  &--loading {
    svg.skeleton-box {
      margin: 0;
    }
  }

  &__title {
    margin-top: $--clb-space-3 !important;
    margin-bottom: $--clb-space-3 !important;
    color: $--clb-color-grey__grey-dark !important;

    @include sm-up {
      margin-bottom: $--clb-space-1 !important;
    }
  }

  &__inner {
    padding: $--clb-space-3 0;
    border-top: 1px solid $--clb-color-grey__grey-lighter;

    @include sm-up {
      padding: $--clb-space-4 0;
    }

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    .ad-approval-row-content__info {
      align-items: stretch !important;
    }

    .ad-approval-row-content__date-wrapper,
    .ad-approval-row-content__action-wrapper {
      @include xs-down {
        display: none;
      }
    }

    &-note {
      height: 100%;
      position: relative;
      padding-left: 20px;

      &::before {
        content: '';
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><circle fill="%23cccfdb" cx="16" cy="16" r="10"/></svg>');
        position: absolute;
        left: 0;
        top: 4px;
        height: 12px;
        width: 12px;
      }
    }

    &-row {
      &:nth-child(2) {
        margin-top: $--clb-space-3;
      }

      &:not(:last-child) {
        .ad-approval-history__inner-note {
          &::after {
            content: '';
            background-color: $--clb-color-grey__grey-lighter;
            position: absolute;
            bottom: -6px;
            top: 16px;
            left: 4px;
            width: 2px;
          }
        }
      }
    }
  }
}
